import React, { useState } from "react";
import { Link } from "react-router-dom";

const MockLandingPage = () => {
  const [selectedPlan, setSelectedPlan] = useState("Starter");
  const [selectedDuration, setSelectedDuration] = useState("monthly");

  const handlePlanChange = (plan: string) => {
    setSelectedPlan(plan);
  };

  const handleDurationChange = (duration: string) => {
    setSelectedDuration(duration);
  };

  return (
    <div>
      <h1 className="text-2xl font-bold">Pricing</h1>
      <div className="flex space-x-2">
        <button
          onClick={() => handlePlanChange("Starter")}
          className={`${
            selectedPlan === "Starter"
              ? "bg-blue-500 text-white"
              : "bg-gray-200"
          } px-4 py-2 rounded`}
        >
          Starter
        </button>
        <button
          onClick={() => handlePlanChange("Business")}
          className={`${
            selectedPlan === "Business"
              ? "bg-blue-500 text-white"
              : "bg-gray-200"
          } px-4 py-2 rounded`}
        >
          Business
        </button>
        <button
          onClick={() => handlePlanChange("Premium")}
          className={`${
            selectedPlan === "Premium"
              ? "bg-blue-500 text-white"
              : "bg-gray-200"
          } px-4 py-2 rounded`}
        >
          Premium
        </button>
        <button
          onClick={() => handlePlanChange("Test")}
          className={`${
            selectedPlan === "Test" ? "bg-blue-500 text-white" : "bg-gray-200"
          } px-4 py-2 rounded`}
        >
          Test
        </button>
      </div>
      <div className="flex space-x-2 mt-4">
        <button
          onClick={() => handleDurationChange("monthly")}
          className={`${
            selectedDuration === "monthly"
              ? "bg-blue-500 text-white"
              : "bg-gray-200"
          } px-4 py-2 rounded`}
        >
          Monthly
        </button>
        <button
          onClick={() => handleDurationChange("yearly")}
          className={`${
            selectedDuration === "yearly"
              ? "bg-blue-500 text-white"
              : "bg-gray-200"
          } px-4 py-2 rounded`}
        >
          Yearly
        </button>
      </div>
      <div className="mt-4">
        {selectedDuration === "monthly" ? (
          <div>
            {selectedPlan === "Starter" && (
              <Link to="/redirect-to-checkout?price_id=price_1P0i2RBbh7hVZzhcW0SdhPYk">
                <p>Starter Monthly Pricing</p>
              </Link>
            )}
            {selectedPlan === "Business" && (
              <Link to="/redirect-to-checkout?price_id=price_1P0hiDBbh7hVZzhccilpmiiN">
                <p>Business Monthly Pricing</p>
              </Link>
            )}
            {selectedPlan === "Premium" && (
              <Link to="/redirect-to-checkout?price_id=price_1P0hnuBbh7hVZzhcYkESHQgQ">
                <p>Premium Monthly Pricing</p>
              </Link>
            )}
            {selectedPlan === "Test" && (
              <Link to="/redirect-to-checkout?price_id=price_1P3eqdBbh7hVZzhc6xNrKabO">
                <p>Test Prod Monthly Pricing</p>
              </Link>
            )}
          </div>
        ) : (
          <div>
            {selectedPlan === "Starter" && (
              <Link to="/redirect-to-checkout?price_id=price_1P0mnJBbh7hVZzhc5C4AjlJB">
                <p>Starter Yearly Pricing</p>
              </Link>
            )}
            {selectedPlan === "Business" && (
              <Link to="/redirect-to-checkout?price_id=price_1P0mpYBbh7hVZzhcXwbgTtYn">
                <p>Business Yearly Pricing</p>
              </Link>
            )}
            {selectedPlan === "Premium" && (
              <Link to="/redirect-to-checkout?price_id=price_1P0moiBbh7hVZzhcnXKefWP3">
                <p>Premium Yearly Pricing</p>
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MockLandingPage;
