import { useAuthenticator } from "@aws-amplify/ui-react";
import { generateClient } from "aws-amplify/api";
import React, { createContext, useEffect, useState } from "react";
import { accountByUser } from "../graphql/queries";

interface AccountContextData {
  accountId: string | null;
}

const initialContextValue: AccountContextData = {
  accountId: null,
};

export const AccountContext =
  createContext<AccountContextData>(initialContextValue);

interface AccountProviderProps {
  children: React.ReactNode;
}

export const AccountProvider = ({ children }: AccountProviderProps) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [accountId, setAccountId] = useState<string | null>(null);

  useEffect(() => {
    if (!user) return;
    const getAccountId = async () => {
      const client = generateClient({
        authMode: "userPool",
      });
      const account = await client.graphql({
        query: accountByUser,
        variables: { userId: user.userId },
      });

      const accountId = account.data.accountByUser.items[0].id;

      setAccountId(accountId);
    };
    if (accountId !== null) {
      return;
    }
    getAccountId();
  }, [user, accountId]);

  return (
    <AccountContext.Provider
      value={{
        accountId,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
