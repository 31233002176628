import React, { useContext, useEffect, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Container } from "@mui/material";
import { Link } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import { usageByAccount } from "../graphql/queries";
import { onUpdateUsage } from "../graphql/subscriptions";
import { AccountContext } from "../contexts/accountContext";

export const Header = () => {
  const { authStatus } = useAuthenticator((context) => [context.user]);
  const [usage, setUsage] = useState<any>(null);
  const [allowedUsage, setAllowedUsage] = useState<any>(null);
  const { accountId } = useContext(AccountContext);

  useEffect(() => {
    if (!accountId) return;

    let sub: any;
    const getUsage = async () => {
      try {
        const client = generateClient({
          authMode: "userPool",
        });
        sub = client
          .graphql({
            query: onUpdateUsage,
            variables: {
              filter: {
                accountId: {
                  eq: accountId,
                },
              },
            },
          })
          .subscribe({
            next: ({ data }) => {
              const newUsage = data.onUpdateUsage.usage;
              const allowedUsage = data.onUpdateUsage.allowedUsage;
              setUsage(newUsage);
              setAllowedUsage(allowedUsage);
            },
          });

        const usage = await client.graphql({
          query: usageByAccount,
          variables: { accountId },
        });

        const currentUsage = usage.data.usageByAccount.items.find(
          ({ from, to }) => {
            const now = new Date();
            return new Date(from) <= now && new Date(to) >= now;
          }
        );

        if (!currentUsage) {
          return;
        }

        setUsage(currentUsage.usage);
        setAllowedUsage(currentUsage.allowedUsage);
      } catch (error) {
        console.log(error);
      }
    };

    getUsage();

    return () => {
      sub.unsubscribe();
    };
  }, [accountId]);

  const isLoggedIn = authStatus === "authenticated";
  return (
    <header className="bg-gray-800">
      <Container>
        <div className="flex flex-col md:flex-row justify-between items-center py-5">
          <Link to={"/"}>
            <span className="text-white text-2xl">ReCraftify</span>
          </Link>
          {isLoggedIn && (
            <>
              <div className="text-white">
                <span>{usage}</span> / <span>{allowedUsage}</span>{" "}
                <span>videos this month</span>
              </div>
              <ul className="flex space-x-4 items-center">
                <li>
                  <Link to="/" className="text-white">
                    Generate video
                  </Link>
                </li>
                <li>
                  <Link to="/content-sources" className="text-white">
                    My videos
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://billing.stripe.com/p/login/aEUbIYdZs4Z11IA3cc"
                    className="text-white"
                    target="_blank"
                  >
                    Account
                  </Link>
                </li>
                <li>
                  <Link to="/logout" className="text-white">
                    Logout
                  </Link>
                </li>
              </ul>
            </>
          )}
        </div>
      </Container>
    </header>
  );
};
