import React from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Authenticator } from "@aws-amplify/ui-react";

interface LayoutProps {
  children: React.ReactNode;
  secure?: boolean;
}

export const Layout = ({ children, secure = true }: LayoutProps) => {
  return (
    <div className="flex flex-col justify-between min-h-screen">
      <div>
        <Header />
        {secure && (
          <Authenticator hideSignUp className="h-screen bg-gray-800">
            <main className="my-5">{children}</main>
          </Authenticator>
        )}
        {!secure && children}
      </div>
      <Footer />
    </div>
  );
};
