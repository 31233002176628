import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Content, ContentFormat } from "../API";
import { contentByContentSource, getContentSource } from "../graphql/queries";
import { generateClient, post } from "aws-amplify/api";
import { Container } from "@mui/material";
import { Button, Heading } from "@aws-amplify/ui-react";
import { getUrl } from "aws-amplify/storage";
import videoPlaceholder from "../assets/images/video-placeholder.jpg";

export const ContentSourcePage = () => {
  const { id } = useParams();

  const [contents, setContents] = useState<Content[]>([]);
  const [contentSourceTitle, setContentSourceTitle] = useState<string | null>(
    null
  );
  const [contentSourceUrl, setContentSourceUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!id) return;
    const fetchContents = async () => {
      const client = generateClient({
        authMode: "userPool",
      });

      const contentSourceResponse = await client.graphql({
        query: getContentSource,
        variables: { id },
      });

      setContentSourceUrl(
        contentSourceResponse.data.getContentSource?.url ?? null
      );

      const contentReponse = await client.graphql({
        query: contentByContentSource,
        variables: { contentSourceId: id },
      });

      const contentSources = await Promise.all(
        contentReponse.data.contentByContentSource.items.map(
          async (content) => {
            console.log("content: ", content);
            const fileName = content.url?.split("/").pop() || "";
            const getUrlResult = await getUrl({
              key: fileName,
              options: {
                accessLevel: "private",
                expiresIn: 3600,
              },
            });
            const contributions = content?.contributions?.map(
              (contribution: any) => JSON.parse(contribution)
            );

            return {
              ...content,
              url: getUrlResult.url.toString(),
              contributions,
            };
          }
        )
      );

      setContents(contentSources);
    };
    fetchContents();
  }, [id]);

  useEffect(() => {
    const getPreview = async () => {
      try {
        if (!contentSourceUrl) {
          return;
        }
        const restOperation = post({
          apiName: "linkPreviewApi",
          path: "/link-preview",
          options: {
            body: {
              url: contentSourceUrl,
            },
          },
        });

        const { title, siteName } = (await (
          await restOperation.response
        ).body.json()) as any;

        setContentSourceTitle(title || siteName);
      } catch (e) {
        console.log(e);
      }
    };

    getPreview();
  }, [contentSourceUrl]);

  return (
    <Container>
      <div className="flex flex-col gap-5">
        <Heading level={2}>{contentSourceTitle ?? "Loading..."}</Heading>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
          {contents.map((content) => (
            <div key={content.id} className="flex flex-col">
              <div
                className="flex sm:flex-1 text-center justify-center items-center aspect-[9/16] rounded-xl shadow-lg bg-cover w-full max-w-[540px] mx-auto"
                style={{ backgroundImage: `url(${videoPlaceholder})` }}
              >
                {content.url && (
                  <video controls className="rounded-xl">
                    <source src={content.url} type="video/mp4" />
                  </video>
                )}
              </div>
              {content?.contributions && (
                <div className="flex flex-col mt-5">
                  <span className="text-xs">
                    Fantastic videos from{" "}
                    <a href="https://pexels.com">Pexels</a> by:
                  </span>
                  <div>
                    {content.contributions.map((contribution, index) => {
                      const isLast =
                        index === content.contributions!.length - 1;
                      return (
                        <div key={index} className="inline">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={(contribution as any).originalUrl}
                          >
                            <span className="text-xs">
                              {(contribution as any).authorName}
                              {!isLast && " | "}
                            </span>
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className="flex flex-col mt-5">
                <a href={content.url || ""} target="_blank" rel="noreferrer">
                  <Button isFullWidth>
                    Download {contentFormatToLabel(content.format || "")}
                  </Button>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

const contentFormatToLabel = (format: string) => {
  switch (format) {
    case ContentFormat.INSTAGRAM_REEL:
      return "Instagram Reel";
    case ContentFormat.TIKTOK:
      return "TikTok";
    case ContentFormat.YOUTUBE_SHORT:
      return "YouTube Short";
    default:
      return "Unknown";
  }
};
