/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateAccount = /* GraphQL */ `subscription OnCreateAccount($filter: ModelSubscriptionAccountFilterInput) {
  onCreateAccount(filter: $filter) {
    id
    userId
    userEmail
    stripeCustomerId
    hasActiveSubscription
    subscriptionType
    contentSources {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAccountSubscriptionVariables,
  APITypes.OnCreateAccountSubscription
>;
export const onUpdateAccount = /* GraphQL */ `subscription OnUpdateAccount($filter: ModelSubscriptionAccountFilterInput) {
  onUpdateAccount(filter: $filter) {
    id
    userId
    userEmail
    stripeCustomerId
    hasActiveSubscription
    subscriptionType
    contentSources {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAccountSubscriptionVariables,
  APITypes.OnUpdateAccountSubscription
>;
export const onDeleteAccount = /* GraphQL */ `subscription OnDeleteAccount($filter: ModelSubscriptionAccountFilterInput) {
  onDeleteAccount(filter: $filter) {
    id
    userId
    userEmail
    stripeCustomerId
    hasActiveSubscription
    subscriptionType
    contentSources {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAccountSubscriptionVariables,
  APITypes.OnDeleteAccountSubscription
>;
export const onCreateContentSource = /* GraphQL */ `subscription OnCreateContentSource(
  $filter: ModelSubscriptionContentSourceFilterInput
) {
  onCreateContentSource(filter: $filter) {
    id
    accountId
    type
    url
    textTitle
    textContent
    contents {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateContentSourceSubscriptionVariables,
  APITypes.OnCreateContentSourceSubscription
>;
export const onUpdateContentSource = /* GraphQL */ `subscription OnUpdateContentSource(
  $filter: ModelSubscriptionContentSourceFilterInput
) {
  onUpdateContentSource(filter: $filter) {
    id
    accountId
    type
    url
    textTitle
    textContent
    contents {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateContentSourceSubscriptionVariables,
  APITypes.OnUpdateContentSourceSubscription
>;
export const onDeleteContentSource = /* GraphQL */ `subscription OnDeleteContentSource(
  $filter: ModelSubscriptionContentSourceFilterInput
) {
  onDeleteContentSource(filter: $filter) {
    id
    accountId
    type
    url
    textTitle
    textContent
    contents {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteContentSourceSubscriptionVariables,
  APITypes.OnDeleteContentSourceSubscription
>;
export const onCreateVideoFlow = /* GraphQL */ `subscription OnCreateVideoFlow($filter: ModelSubscriptionVideoFlowFilterInput) {
  onCreateVideoFlow(filter: $filter) {
    id
    accountId
    contentSourceId
    status
    videoUrl
    fileName
    key
    contributions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateVideoFlowSubscriptionVariables,
  APITypes.OnCreateVideoFlowSubscription
>;
export const onUpdateVideoFlow = /* GraphQL */ `subscription OnUpdateVideoFlow($filter: ModelSubscriptionVideoFlowFilterInput) {
  onUpdateVideoFlow(filter: $filter) {
    id
    accountId
    contentSourceId
    status
    videoUrl
    fileName
    key
    contributions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateVideoFlowSubscriptionVariables,
  APITypes.OnUpdateVideoFlowSubscription
>;
export const onDeleteVideoFlow = /* GraphQL */ `subscription OnDeleteVideoFlow($filter: ModelSubscriptionVideoFlowFilterInput) {
  onDeleteVideoFlow(filter: $filter) {
    id
    accountId
    contentSourceId
    status
    videoUrl
    fileName
    key
    contributions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteVideoFlowSubscriptionVariables,
  APITypes.OnDeleteVideoFlowSubscription
>;
export const onCreateContent = /* GraphQL */ `subscription OnCreateContent($filter: ModelSubscriptionContentFilterInput) {
  onCreateContent(filter: $filter) {
    id
    accountId
    contentSourceId
    format
    type
    url
    key
    contributions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateContentSubscriptionVariables,
  APITypes.OnCreateContentSubscription
>;
export const onUpdateContent = /* GraphQL */ `subscription OnUpdateContent($filter: ModelSubscriptionContentFilterInput) {
  onUpdateContent(filter: $filter) {
    id
    accountId
    contentSourceId
    format
    type
    url
    key
    contributions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateContentSubscriptionVariables,
  APITypes.OnUpdateContentSubscription
>;
export const onDeleteContent = /* GraphQL */ `subscription OnDeleteContent($filter: ModelSubscriptionContentFilterInput) {
  onDeleteContent(filter: $filter) {
    id
    accountId
    contentSourceId
    format
    type
    url
    key
    contributions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteContentSubscriptionVariables,
  APITypes.OnDeleteContentSubscription
>;
export const onCreateUsage = /* GraphQL */ `subscription OnCreateUsage($filter: ModelSubscriptionUsageFilterInput) {
  onCreateUsage(filter: $filter) {
    id
    accountId
    from
    to
    usage
    allowedUsage
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUsageSubscriptionVariables,
  APITypes.OnCreateUsageSubscription
>;
export const onUpdateUsage = /* GraphQL */ `subscription OnUpdateUsage($filter: ModelSubscriptionUsageFilterInput) {
  onUpdateUsage(filter: $filter) {
    id
    accountId
    from
    to
    usage
    allowedUsage
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUsageSubscriptionVariables,
  APITypes.OnUpdateUsageSubscription
>;
export const onDeleteUsage = /* GraphQL */ `subscription OnDeleteUsage($filter: ModelSubscriptionUsageFilterInput) {
  onDeleteUsage(filter: $filter) {
    id
    accountId
    from
    to
    usage
    allowedUsage
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUsageSubscriptionVariables,
  APITypes.OnDeleteUsageSubscription
>;
export const onCreateUsageReservation = /* GraphQL */ `subscription OnCreateUsageReservation(
  $filter: ModelSubscriptionUsageReservationFilterInput
) {
  onCreateUsageReservation(filter: $filter) {
    id
    accountId
    flowId
    date
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUsageReservationSubscriptionVariables,
  APITypes.OnCreateUsageReservationSubscription
>;
export const onUpdateUsageReservation = /* GraphQL */ `subscription OnUpdateUsageReservation(
  $filter: ModelSubscriptionUsageReservationFilterInput
) {
  onUpdateUsageReservation(filter: $filter) {
    id
    accountId
    flowId
    date
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUsageReservationSubscriptionVariables,
  APITypes.OnUpdateUsageReservationSubscription
>;
export const onDeleteUsageReservation = /* GraphQL */ `subscription OnDeleteUsageReservation(
  $filter: ModelSubscriptionUsageReservationFilterInput
) {
  onDeleteUsageReservation(filter: $filter) {
    id
    accountId
    flowId
    date
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUsageReservationSubscriptionVariables,
  APITypes.OnDeleteUsageReservationSubscription
>;
