import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

const RedirectToCheckoutPage = () => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleClick = async () => {
    const stripe = await loadStripe(
      process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!
    );

    if (!stripe) {
      return;
    }

    const priceId = searchParams.get("price_id");

    if (!priceId) {
      return;
    }

    const { error } = await stripe.redirectToCheckout({
      lineItems: [{ price: priceId, quantity: 1 }],
      mode: "subscription",
      successUrl: `${process.env.REACT_APP_HOST}/success-payment`,
      cancelUrl: `${process.env.REACT_APP_HOST}/cancel`,
    });

    if (error) {
      // Handle error
      console.error(error);
    } else {
      // Redirect to checkout
      navigate("/checkout");
    }
  };

  useEffect(() => {
    handleClick();
  }, []);

  return null;
};

export default RedirectToCheckoutPage;
