import "@aws-amplify/ui-react/styles.css";
import { Container } from "@mui/material";
import { VideoGeneratorForm } from "../components/VideoGeneratorForm";

function Home() {
  return (
    <Container>
      <VideoGeneratorForm />
    </Container>
  );
}

export default Home;
