import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import config from "./aws-exports";
import Home from "./pages/HomePage";
import MockLandingPage from "./pages/MockLandingPage";
import RedirectToCheckoutPage from "./pages/RedirectToCheckoutPage";
import SuccessPaymentPage from "./pages/SuccessPaymentPage";
import { Layout } from "./components/LoggedInLayout";
import { Authenticator } from "@aws-amplify/ui-react";
import { Logout } from "./hooks/useLogout";
import { AccountProvider } from "./contexts/accountContext";
import { ListContentSourcesPage } from "./pages/ListContentSourcesPage";
import { ContentSourcePage } from "./pages/ContentSourcePage";

Amplify.configure(config);

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <Home />
      </Layout>
    ),
  },
  {
    path: "/content-sources",
    element: (
      <Layout>
        <ListContentSourcesPage />
      </Layout>
    ),
  },
  {
    path: "/content-sources/:id",
    element: (
      <Layout>
        <ContentSourcePage />
      </Layout>
    ),
  },
  {
    path: "/redirect-to-checkout/",
    element: <RedirectToCheckoutPage />,
  },
  {
    path: "/success-payment/",
    element: <SuccessPaymentPage />,
  },
  {
    path: "/logout/",
    element: <Logout />,
  },
  {
    path: "/mock/",
    element: <MockLandingPage />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <AccountProvider>
        <RouterProvider router={router} />
      </AccountProvider>
    </Authenticator.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
