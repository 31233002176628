/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getAccount = /* GraphQL */ `query GetAccount($id: ID!) {
  getAccount(id: $id) {
    id
    userId
    userEmail
    stripeCustomerId
    hasActiveSubscription
    subscriptionType
    contentSources {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountQueryVariables,
  APITypes.GetAccountQuery
>;
export const listAccounts = /* GraphQL */ `query ListAccounts(
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      userEmail
      stripeCustomerId
      hasActiveSubscription
      subscriptionType
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccountsQueryVariables,
  APITypes.ListAccountsQuery
>;
export const accountByUser = /* GraphQL */ `query AccountByUser(
  $userId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  accountByUser(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      userEmail
      stripeCustomerId
      hasActiveSubscription
      subscriptionType
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AccountByUserQueryVariables,
  APITypes.AccountByUserQuery
>;
export const accountsByStripeCustomerId = /* GraphQL */ `query AccountsByStripeCustomerId(
  $stripeCustomerId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  accountsByStripeCustomerId(
    stripeCustomerId: $stripeCustomerId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      userId
      userEmail
      stripeCustomerId
      hasActiveSubscription
      subscriptionType
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AccountsByStripeCustomerIdQueryVariables,
  APITypes.AccountsByStripeCustomerIdQuery
>;
export const getContentSource = /* GraphQL */ `query GetContentSource($id: ID!) {
  getContentSource(id: $id) {
    id
    accountId
    type
    url
    textTitle
    textContent
    contents {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContentSourceQueryVariables,
  APITypes.GetContentSourceQuery
>;
export const listContentSources = /* GraphQL */ `query ListContentSources(
  $filter: ModelContentSourceFilterInput
  $limit: Int
  $nextToken: String
) {
  listContentSources(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountId
      type
      url
      textTitle
      textContent
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContentSourcesQueryVariables,
  APITypes.ListContentSourcesQuery
>;
export const contentSourceByAccount = /* GraphQL */ `query ContentSourceByAccount(
  $accountId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContentSourceFilterInput
  $limit: Int
  $nextToken: String
) {
  contentSourceByAccount(
    accountId: $accountId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountId
      type
      url
      textTitle
      textContent
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContentSourceByAccountQueryVariables,
  APITypes.ContentSourceByAccountQuery
>;
export const contentSourceByUrl = /* GraphQL */ `query ContentSourceByUrl(
  $url: String!
  $sortDirection: ModelSortDirection
  $filter: ModelContentSourceFilterInput
  $limit: Int
  $nextToken: String
) {
  contentSourceByUrl(
    url: $url
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountId
      type
      url
      textTitle
      textContent
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContentSourceByUrlQueryVariables,
  APITypes.ContentSourceByUrlQuery
>;
export const getVideoFlow = /* GraphQL */ `query GetVideoFlow($id: ID!) {
  getVideoFlow(id: $id) {
    id
    accountId
    contentSourceId
    status
    videoUrl
    fileName
    key
    contributions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetVideoFlowQueryVariables,
  APITypes.GetVideoFlowQuery
>;
export const listVideoFlows = /* GraphQL */ `query ListVideoFlows(
  $filter: ModelVideoFlowFilterInput
  $limit: Int
  $nextToken: String
) {
  listVideoFlows(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountId
      contentSourceId
      status
      videoUrl
      fileName
      key
      contributions
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListVideoFlowsQueryVariables,
  APITypes.ListVideoFlowsQuery
>;
export const videoFlowsByAccount = /* GraphQL */ `query VideoFlowsByAccount(
  $accountId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelVideoFlowFilterInput
  $limit: Int
  $nextToken: String
) {
  videoFlowsByAccount(
    accountId: $accountId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountId
      contentSourceId
      status
      videoUrl
      fileName
      key
      contributions
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VideoFlowsByAccountQueryVariables,
  APITypes.VideoFlowsByAccountQuery
>;
export const videoFlowsByContentSource = /* GraphQL */ `query VideoFlowsByContentSource(
  $contentSourceId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelVideoFlowFilterInput
  $limit: Int
  $nextToken: String
) {
  videoFlowsByContentSource(
    contentSourceId: $contentSourceId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountId
      contentSourceId
      status
      videoUrl
      fileName
      key
      contributions
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.VideoFlowsByContentSourceQueryVariables,
  APITypes.VideoFlowsByContentSourceQuery
>;
export const getContent = /* GraphQL */ `query GetContent($id: ID!) {
  getContent(id: $id) {
    id
    accountId
    contentSourceId
    format
    type
    url
    key
    contributions
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContentQueryVariables,
  APITypes.GetContentQuery
>;
export const listContents = /* GraphQL */ `query ListContents(
  $filter: ModelContentFilterInput
  $limit: Int
  $nextToken: String
) {
  listContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountId
      contentSourceId
      format
      type
      url
      key
      contributions
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContentsQueryVariables,
  APITypes.ListContentsQuery
>;
export const contentByAccount = /* GraphQL */ `query ContentByAccount(
  $accountId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContentFilterInput
  $limit: Int
  $nextToken: String
) {
  contentByAccount(
    accountId: $accountId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountId
      contentSourceId
      format
      type
      url
      key
      contributions
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContentByAccountQueryVariables,
  APITypes.ContentByAccountQuery
>;
export const contentByContentSource = /* GraphQL */ `query ContentByContentSource(
  $contentSourceId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContentFilterInput
  $limit: Int
  $nextToken: String
) {
  contentByContentSource(
    contentSourceId: $contentSourceId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountId
      contentSourceId
      format
      type
      url
      key
      contributions
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContentByContentSourceQueryVariables,
  APITypes.ContentByContentSourceQuery
>;
export const getUsage = /* GraphQL */ `query GetUsage($id: ID!) {
  getUsage(id: $id) {
    id
    accountId
    from
    to
    usage
    allowedUsage
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUsageQueryVariables, APITypes.GetUsageQuery>;
export const listUsages = /* GraphQL */ `query ListUsages(
  $filter: ModelUsageFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountId
      from
      to
      usage
      allowedUsage
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUsagesQueryVariables,
  APITypes.ListUsagesQuery
>;
export const usageByAccount = /* GraphQL */ `query UsageByAccount(
  $accountId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUsageFilterInput
  $limit: Int
  $nextToken: String
) {
  usageByAccount(
    accountId: $accountId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountId
      from
      to
      usage
      allowedUsage
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UsageByAccountQueryVariables,
  APITypes.UsageByAccountQuery
>;
export const getUsageReservation = /* GraphQL */ `query GetUsageReservation($id: ID!) {
  getUsageReservation(id: $id) {
    id
    accountId
    flowId
    date
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUsageReservationQueryVariables,
  APITypes.GetUsageReservationQuery
>;
export const listUsageReservations = /* GraphQL */ `query ListUsageReservations(
  $filter: ModelUsageReservationFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsageReservations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountId
      flowId
      date
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUsageReservationsQueryVariables,
  APITypes.ListUsageReservationsQuery
>;
export const usageReservationByAccount = /* GraphQL */ `query UsageReservationByAccount(
  $accountId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUsageReservationFilterInput
  $limit: Int
  $nextToken: String
) {
  usageReservationByAccount(
    accountId: $accountId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountId
      flowId
      date
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UsageReservationByAccountQueryVariables,
  APITypes.UsageReservationByAccountQuery
>;
export const usageReservationByFlow = /* GraphQL */ `query UsageReservationByFlow(
  $flowId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUsageReservationFilterInput
  $limit: Int
  $nextToken: String
) {
  usageReservationByFlow(
    flowId: $flowId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountId
      flowId
      date
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UsageReservationByFlowQueryVariables,
  APITypes.UsageReservationByFlowQuery
>;
