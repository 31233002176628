import { useAuthenticator } from "@aws-amplify/ui-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Logout = () => {
  const { signOut, isPending } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();
  useEffect(() => {
    signOut();
    navigate("/");
  }, []);
  if (isPending) {
    return <div>Logging out...</div>;
  }
  return null;
};
