import React from "react";
import { Container } from "@mui/material";

export const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white">
      <Container>
        <div className="text-center py-2">
          &copy; {new Date().getFullYear()} ReCraftify. All rights reserved.
        </div>
      </Container>
    </footer>
  );
};
