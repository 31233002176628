import React, { useContext, useEffect, useState } from "react";
import { AccountContext } from "../contexts/accountContext";
import { Container } from "@mui/material";
import { Heading } from "@aws-amplify/ui-react";
import { generateClient } from "aws-amplify/api";
import { ContentSource } from "../API";
import { contentSourceByAccount } from "../graphql/queries";
import { ContentSourceItem } from "../components/ContentSourceItem";

export const ListContentSourcesPage = () => {
  const { accountId } = useContext(AccountContext);
  const [contentSources, setContentSources] = useState<ContentSource[]>([]);

  useEffect(() => {
    if (!accountId) return;
    const fetchContentSources = async () => {
      const client = generateClient({
        authMode: "userPool",
      });

      const contentSourcesReponse = await client.graphql({
        query: contentSourceByAccount,
        variables: { accountId },
      });

      setContentSources(
        contentSourcesReponse.data.contentSourceByAccount.items
      );
    };
    fetchContentSources();
  }, [accountId]);

  return (
    <Container>
      <div className="flex flex-col gap-5">
        <Heading level={2}>Content sources</Heading>
        <div className="flex flex-col gap-5">
          {contentSources.map((contentSource) => (
            <ContentSourceItem
              key={contentSource.id}
              contentSource={contentSource}
            />
          ))}
        </div>
      </div>
    </Container>
  );
};
