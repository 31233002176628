import React, { useEffect, useRef, useState } from "react";
import videoPlaceholder from "../assets/images/video-placeholder.jpg";
import { BsRecordCircleFill } from "react-icons/bs";
import "react-toastify/dist/ReactToastify.css";
import { post } from "aws-amplify/api";
import { generateClient } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import {
  Button,
  Heading,
  Input,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { ContentSourceType, JobStatus } from "../API";
import { onUpdateVideoFlow } from "../graphql/subscriptions";
import { getUrl } from "aws-amplify/storage";

export const VideoGeneratorForm = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [videoFlowId, setVideoFlowId] = useState<undefined | string>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [videoFlowStatus, setVideoFlowStatus] = useState<undefined | JobStatus>(
    undefined
  );
  const [url, setUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [contributions, setContributions] = useState<any[]>([]);
  const subscriptionVideoFlow = useRef<any>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { identityId } = await fetchAuthSession();
      await post({
        apiName: "startVideoFlowAPi",
        path: "/video-flow",
        options: {
          headers: {
            "Content-Type": "application/json",
          },
          body: {
            userId: user.userId,
            contentUrl: url,
            contentType: ContentSourceType.BLOG,
            identityId: identityId!,
          },
        },
      }).response.then(async (response) => {
        const resVideoFlowId = (await response.body.json()) as any;
        console.log("resVideoFlowId", resVideoFlowId);
        setVideoFlowId(resVideoFlowId);
        setVideoFlowStatus(JobStatus.IN_PROGRESS);
      });
    } catch (error: any) {
      setIsLoading(false);
      if (error instanceof Error) {
        setError(error.message);
      } else if (error.response && error.response.status === 403) {
        setError(
          "You don't have any more free credits left. Please upgrade. If a video is beeing created, it has temporarily reserved a credit until finished."
        );
      } else {
        setError("An unknown error occurred");
      }
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("An unknown error occurred");
      }
    }
  };

  useEffect(() => {
    const client = generateClient();
    if (videoFlowId) {
      const sub = client
        .graphql({
          query: onUpdateVideoFlow,
          variables: {
            filter: {
              id: {
                eq: videoFlowId,
              },
            },
          },
        })
        .subscribe({
          next: async ({ data }) => {
            if (data) {
              setVideoFlowStatus(data.onUpdateVideoFlow.status);
              console.log("data.onUpdateVideoFlow: ", data.onUpdateVideoFlow);
              console.log("JSON: ", JSON.stringify(data.onUpdateVideoFlow));
              if (data.onUpdateVideoFlow?.fileName) {
                console.log("FileName: ", data.onUpdateVideoFlow?.fileName);
                const getUrlResult = await getUrl({
                  key: `${data.onUpdateVideoFlow?.fileName}.mp4`,
                  options: {
                    accessLevel: "private",
                    expiresIn: 3600,
                  },
                });
                setVideoUrl(getUrlResult.url.toString());
              }

              if (data.onUpdateVideoFlow?.contributions) {
                const contributions =
                  data.onUpdateVideoFlow?.contributions?.map(
                    (contribution: any) => JSON.parse(contribution)
                  );
                setContributions(contributions);
              }
            }
          },
          error: (error) => console.warn(error),
        });
      subscriptionVideoFlow.current = sub;
    }
  }, [videoFlowId]);

  useEffect(() => {
    if (videoFlowStatus === JobStatus.FAILED) {
      setIsLoading(false);
      subscriptionVideoFlow.current.unsubscribe();
    }
    if (videoFlowStatus === JobStatus.IN_PROGRESS) {
      setIsLoading(true);
    }
    if (videoFlowStatus === JobStatus.COMPLETED) {
      setIsLoading(false);
      subscriptionVideoFlow.current.unsubscribe();
    }
  }, [videoFlowStatus]);

  const handleReset = () => {
    setUrl("");
    setVideoFlowId(undefined);
    setVideoFlowStatus(undefined);
    setVideoUrl(undefined);
    setError(undefined);
  };

  return (
    <div className="flex flex-col sm:flex-row">
      <div className="flex-1 flex m-5 justify-center items-center">
        <div className="flex-1 flex flex-col gap-10">
          <Heading level={2}>Blog to TikTok video</Heading>
          <form
            onSubmit={handleSubmit}
            onReset={handleReset}
            className="flex-1 flex flex-col gap-5"
          >
            <Input
              type="text"
              value={url}
              placeholder="Enter URL to blog post"
              onChange={(e) => setUrl(e.target.value)}
              disabled={isLoading || videoFlowStatus === JobStatus.COMPLETED}
            />
            {videoFlowStatus === JobStatus.COMPLETED && (
              <a href={videoUrl} target="blank">
                <Button type="button" variation="primary" isFullWidth>
                  Download video
                </Button>
              </a>
            )}
            {videoFlowStatus === JobStatus.COMPLETED && (
              <Button type="reset" isFullWidth isLoading={isLoading}>
                Create another video
              </Button>
            )}
            {(videoFlowStatus === undefined ||
              (videoFlowStatus !== JobStatus.COMPLETED &&
                videoFlowStatus !== JobStatus.FAILED)) && (
              <Button type="submit" isFullWidth isLoading={isLoading}>
                Generate video
              </Button>
            )}
            {contributions.length > 0 && (
              <div className="flex flex-col gap-2 mt-5">
                <Heading>
                  Fantastic videos from <a href="https://pexels.com">Pexels</a>{" "}
                  by:
                </Heading>
                <div>
                  {contributions.map((contribution, index) => {
                    const isLast = index === contributions.length - 1;
                    return (
                      <div key={index} className="inline">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={contribution?.originalUrl}
                        >
                          <span>
                            {contribution?.authorName}
                            {!isLast && " | "}
                          </span>
                        </a>
                      </div>
                    );
                  })}
                </div>
                <span className="italic">
                  Feel free to check them out and send them a thanks.
                </span>
              </div>
            )}
          </form>
        </div>
      </div>
      <div
        className="flex sm:flex-1 text-center justify-center items-center aspect-[9/16] m-5 rounded-xl shadow-lg bg-cover max-w-[45vh]"
        style={{ backgroundImage: `url(${videoPlaceholder})` }}
      >
        {videoFlowStatus === undefined && isLoading === false && (
          <p className="p-5">Enter a URL to get started</p>
        )}
        {isLoading && (
          <div className="flex flex-col justify-center items-center">
            <p className="p-5">
              We are creating your video, please wait. Usually between 30sec -
              2min depending on video length
            </p>
            <div className="relative">
              <div className="bg-red-500 w-6 h-6 top-3 left-3 rounded-full absolute animate-pulse"></div>
              <BsRecordCircleFill size={50} className="relative z-10" />
            </div>
          </div>
        )}
        {videoFlowStatus === JobStatus.COMPLETED && (
          <video controls className="rounded-xl">
            <source src={videoUrl} type="video/mp4" />
          </video>
        )}
        {error && <div>{error}</div>}
      </div>
    </div>
  );
};

// h-screen max-h-[177.79vw] xs:max-h-[960px] min-h-[320px] w-[56.25vh] max-w-[100vw] xs:max-w-[540px] min-w-[180px]
