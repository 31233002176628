import { Button, Card, Heading, Text } from "@aws-amplify/ui-react";
import { Container } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Layout } from "../components/LoggedInLayout";

const SuccessPaymentPage = () => {
  return (
    <Layout secure={false}>
      <Container>
        <Card>
          <Heading level={3}>Congratulations, payment successful!</Heading>
          <br />
          <Text>
            Please check your email.
            <br />
            We have sent a temporary password to login and active your account.
          </Text>
          <br />
          <Link to="/">
            <Button variation="primary">Login here</Button>
          </Link>
        </Card>
      </Container>
    </Layout>
  );
};

export default SuccessPaymentPage;
