import React, { useEffect, useState } from "react";
import { ContentSource } from "../API";
import { post } from "aws-amplify/api";
import { Link } from "react-router-dom";
import { Button, Heading } from "@aws-amplify/ui-react";

interface ContentSourceItemProps {
  contentSource: ContentSource;
}

interface PreviewData {
  title: string;
  description: string;
}

export const ContentSourceItem = ({
  contentSource,
}: ContentSourceItemProps) => {
  const [previewData, setPreviewData] = useState<PreviewData | null>(null);

  useEffect(() => {
    async function getPreview() {
      try {
        const { url } = contentSource;
        if (!url) {
          return;
        }
        const restOperation = post({
          apiName: "linkPreviewApi",
          path: "/link-preview",
          options: {
            body: {
              url,
            },
          },
        });

        const { title, siteName } = (await (
          await restOperation.response
        ).body.json()) as any;

        setPreviewData({
          title: title || siteName,
          description: "",
        });
      } catch (e) {
        console.log(e);
      }
    }

    getPreview();
  }, [contentSource]);

  return (
    <div className="flex flex-col sm:flex-row gap-5 shadow-lg p-5 rounded-lg bg-gray-50">
      <div className="flex-1 flex flex-col gap-2">
        <Heading level={5}>{previewData?.title ?? "Loading.."}</Heading>
        {contentSource.url && (
          <Link to={contentSource.url} target="_blank">
            {contentSource.url}
          </Link>
        )}
      </div>
      <div className="flex sm:justify-end items-center">
        <Link className="w-full" to={`/content-sources/${contentSource.id}`}>
          <Button variation="primary" isFullWidth>
            Videos
          </Button>
        </Link>
      </div>
    </div>
  );
};
