/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "linkPreviewApi",
            "endpoint": "https://43zt4eok36.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "startVideoFlowAPi",
            "endpoint": "https://nfbcskg2x4.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "stripeWebhookApi",
            "endpoint": "https://lzewn0nk8e.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://gni36yhubfg2bm6snuqw6i6ug4.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-x6qdf7nldrebpoeujt7ixyuaae",
    "aws_cognito_identity_pool_id": "eu-central-1:47c2666f-ddc7-4dc4-8473-0bfc3e796a6e",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_Ducoa8hF7",
    "aws_user_pools_web_client_id": "7fj2e3bq5jqhclmdols826iasd",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "generatedvideos173450-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
