/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateAccountInput = {
  id?: string | null,
  userId: string,
  userEmail: string,
  stripeCustomerId: string,
  hasActiveSubscription: boolean,
  subscriptionType: SubscriptionType,
};

export enum SubscriptionType {
  STARTER_MONTHLY = "STARTER_MONTHLY",
  STARTER_YEARLY = "STARTER_YEARLY",
  BUSINESS_MONTHLY = "BUSINESS_MONTHLY",
  BUSINESS_YEARLY = "BUSINESS_YEARLY",
  PREMIUM_MONTHLY = "PREMIUM_MONTHLY",
  PREMIUM_YEARLY = "PREMIUM_YEARLY",
  TEST_MONTHLY = "TEST_MONTHLY",
}


export type ModelAccountConditionInput = {
  userId?: ModelIDInput | null,
  userEmail?: ModelStringInput | null,
  stripeCustomerId?: ModelStringInput | null,
  hasActiveSubscription?: ModelBooleanInput | null,
  subscriptionType?: ModelSubscriptionTypeInput | null,
  and?: Array< ModelAccountConditionInput | null > | null,
  or?: Array< ModelAccountConditionInput | null > | null,
  not?: ModelAccountConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelSubscriptionTypeInput = {
  eq?: SubscriptionType | null,
  ne?: SubscriptionType | null,
};

export type Account = {
  __typename: "Account",
  id: string,
  userId: string,
  userEmail: string,
  stripeCustomerId: string,
  hasActiveSubscription: boolean,
  subscriptionType: SubscriptionType,
  contentSources?: ModelContentSourceConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelContentSourceConnection = {
  __typename: "ModelContentSourceConnection",
  items:  Array<ContentSource | null >,
  nextToken?: string | null,
};

export type ContentSource = {
  __typename: "ContentSource",
  id: string,
  accountId: string,
  type?: ContentSourceType | null,
  url?: string | null,
  textTitle?: string | null,
  textContent?: string | null,
  contents?: ModelContentConnection | null,
  createdAt: string,
  updatedAt: string,
};

export enum ContentSourceType {
  BLOG = "BLOG",
  INSTAGRAM = "INSTAGRAM",
  TEXT = "TEXT",
}


export type ModelContentConnection = {
  __typename: "ModelContentConnection",
  items:  Array<Content | null >,
  nextToken?: string | null,
};

export type Content = {
  __typename: "Content",
  id: string,
  accountId: string,
  contentSourceId: string,
  format?: ContentFormat | null,
  type?: ContentType | null,
  url?: string | null,
  key?: string | null,
  contributions?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
};

export enum ContentFormat {
  TIKTOK = "TIKTOK",
  YOUTUBE_SHORT = "YOUTUBE_SHORT",
  INSTAGRAM_REEL = "INSTAGRAM_REEL",
}


export enum ContentType {
  VIDEO = "VIDEO",
}


export type UpdateAccountInput = {
  id: string,
  userId?: string | null,
  userEmail?: string | null,
  stripeCustomerId?: string | null,
  hasActiveSubscription?: boolean | null,
  subscriptionType?: SubscriptionType | null,
};

export type DeleteAccountInput = {
  id: string,
};

export type CreateContentSourceInput = {
  id?: string | null,
  accountId: string,
  type?: ContentSourceType | null,
  url?: string | null,
  textTitle?: string | null,
  textContent?: string | null,
};

export type ModelContentSourceConditionInput = {
  accountId?: ModelIDInput | null,
  type?: ModelContentSourceTypeInput | null,
  url?: ModelStringInput | null,
  textTitle?: ModelStringInput | null,
  textContent?: ModelStringInput | null,
  and?: Array< ModelContentSourceConditionInput | null > | null,
  or?: Array< ModelContentSourceConditionInput | null > | null,
  not?: ModelContentSourceConditionInput | null,
};

export type ModelContentSourceTypeInput = {
  eq?: ContentSourceType | null,
  ne?: ContentSourceType | null,
};

export type UpdateContentSourceInput = {
  id: string,
  accountId?: string | null,
  type?: ContentSourceType | null,
  url?: string | null,
  textTitle?: string | null,
  textContent?: string | null,
};

export type DeleteContentSourceInput = {
  id: string,
};

export type CreateVideoFlowInput = {
  id?: string | null,
  accountId: string,
  contentSourceId: string,
  status: JobStatus,
  videoUrl?: string | null,
  fileName?: string | null,
  key?: string | null,
  contributions?: Array< string | null > | null,
};

export enum JobStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}


export type ModelVideoFlowConditionInput = {
  accountId?: ModelIDInput | null,
  contentSourceId?: ModelIDInput | null,
  status?: ModelJobStatusInput | null,
  videoUrl?: ModelStringInput | null,
  fileName?: ModelStringInput | null,
  key?: ModelStringInput | null,
  contributions?: ModelStringInput | null,
  and?: Array< ModelVideoFlowConditionInput | null > | null,
  or?: Array< ModelVideoFlowConditionInput | null > | null,
  not?: ModelVideoFlowConditionInput | null,
};

export type ModelJobStatusInput = {
  eq?: JobStatus | null,
  ne?: JobStatus | null,
};

export type VideoFlow = {
  __typename: "VideoFlow",
  id: string,
  accountId: string,
  contentSourceId: string,
  status: JobStatus,
  videoUrl?: string | null,
  fileName?: string | null,
  key?: string | null,
  contributions?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateVideoFlowInput = {
  id: string,
  accountId?: string | null,
  contentSourceId?: string | null,
  status?: JobStatus | null,
  videoUrl?: string | null,
  fileName?: string | null,
  key?: string | null,
  contributions?: Array< string | null > | null,
};

export type DeleteVideoFlowInput = {
  id: string,
};

export type CreateContentInput = {
  id?: string | null,
  accountId: string,
  contentSourceId: string,
  format?: ContentFormat | null,
  type?: ContentType | null,
  url?: string | null,
  key?: string | null,
  contributions?: Array< string | null > | null,
};

export type ModelContentConditionInput = {
  accountId?: ModelIDInput | null,
  contentSourceId?: ModelIDInput | null,
  format?: ModelContentFormatInput | null,
  type?: ModelContentTypeInput | null,
  url?: ModelStringInput | null,
  key?: ModelStringInput | null,
  contributions?: ModelStringInput | null,
  and?: Array< ModelContentConditionInput | null > | null,
  or?: Array< ModelContentConditionInput | null > | null,
  not?: ModelContentConditionInput | null,
};

export type ModelContentFormatInput = {
  eq?: ContentFormat | null,
  ne?: ContentFormat | null,
};

export type ModelContentTypeInput = {
  eq?: ContentType | null,
  ne?: ContentType | null,
};

export type UpdateContentInput = {
  id: string,
  accountId?: string | null,
  contentSourceId?: string | null,
  format?: ContentFormat | null,
  type?: ContentType | null,
  url?: string | null,
  key?: string | null,
  contributions?: Array< string | null > | null,
};

export type DeleteContentInput = {
  id: string,
};

export type CreateUsageInput = {
  id?: string | null,
  accountId: string,
  from: string,
  to: string,
  usage: number,
  allowedUsage: number,
};

export type ModelUsageConditionInput = {
  accountId?: ModelIDInput | null,
  from?: ModelStringInput | null,
  to?: ModelStringInput | null,
  usage?: ModelIntInput | null,
  allowedUsage?: ModelIntInput | null,
  and?: Array< ModelUsageConditionInput | null > | null,
  or?: Array< ModelUsageConditionInput | null > | null,
  not?: ModelUsageConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Usage = {
  __typename: "Usage",
  id: string,
  accountId: string,
  from: string,
  to: string,
  usage: number,
  allowedUsage: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUsageInput = {
  id: string,
  accountId?: string | null,
  from?: string | null,
  to?: string | null,
  usage?: number | null,
  allowedUsage?: number | null,
};

export type DeleteUsageInput = {
  id: string,
};

export type CreateUsageReservationInput = {
  id?: string | null,
  accountId: string,
  flowId: string,
  date: string,
};

export type ModelUsageReservationConditionInput = {
  accountId?: ModelIDInput | null,
  flowId?: ModelIDInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelUsageReservationConditionInput | null > | null,
  or?: Array< ModelUsageReservationConditionInput | null > | null,
  not?: ModelUsageReservationConditionInput | null,
};

export type UsageReservation = {
  __typename: "UsageReservation",
  id: string,
  accountId: string,
  flowId: string,
  date: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUsageReservationInput = {
  id: string,
  accountId?: string | null,
  flowId?: string | null,
  date?: string | null,
};

export type DeleteUsageReservationInput = {
  id: string,
};

export type ModelAccountFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  userEmail?: ModelStringInput | null,
  stripeCustomerId?: ModelStringInput | null,
  hasActiveSubscription?: ModelBooleanInput | null,
  subscriptionType?: ModelSubscriptionTypeInput | null,
  and?: Array< ModelAccountFilterInput | null > | null,
  or?: Array< ModelAccountFilterInput | null > | null,
  not?: ModelAccountFilterInput | null,
};

export type ModelAccountConnection = {
  __typename: "ModelAccountConnection",
  items:  Array<Account | null >,
  nextToken?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelContentSourceFilterInput = {
  id?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  type?: ModelContentSourceTypeInput | null,
  url?: ModelStringInput | null,
  textTitle?: ModelStringInput | null,
  textContent?: ModelStringInput | null,
  and?: Array< ModelContentSourceFilterInput | null > | null,
  or?: Array< ModelContentSourceFilterInput | null > | null,
  not?: ModelContentSourceFilterInput | null,
};

export type ModelVideoFlowFilterInput = {
  id?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  contentSourceId?: ModelIDInput | null,
  status?: ModelJobStatusInput | null,
  videoUrl?: ModelStringInput | null,
  fileName?: ModelStringInput | null,
  key?: ModelStringInput | null,
  contributions?: ModelStringInput | null,
  and?: Array< ModelVideoFlowFilterInput | null > | null,
  or?: Array< ModelVideoFlowFilterInput | null > | null,
  not?: ModelVideoFlowFilterInput | null,
};

export type ModelVideoFlowConnection = {
  __typename: "ModelVideoFlowConnection",
  items:  Array<VideoFlow | null >,
  nextToken?: string | null,
};

export type ModelContentFilterInput = {
  id?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  contentSourceId?: ModelIDInput | null,
  format?: ModelContentFormatInput | null,
  type?: ModelContentTypeInput | null,
  url?: ModelStringInput | null,
  key?: ModelStringInput | null,
  contributions?: ModelStringInput | null,
  and?: Array< ModelContentFilterInput | null > | null,
  or?: Array< ModelContentFilterInput | null > | null,
  not?: ModelContentFilterInput | null,
};

export type ModelUsageFilterInput = {
  id?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  from?: ModelStringInput | null,
  to?: ModelStringInput | null,
  usage?: ModelIntInput | null,
  allowedUsage?: ModelIntInput | null,
  and?: Array< ModelUsageFilterInput | null > | null,
  or?: Array< ModelUsageFilterInput | null > | null,
  not?: ModelUsageFilterInput | null,
};

export type ModelUsageConnection = {
  __typename: "ModelUsageConnection",
  items:  Array<Usage | null >,
  nextToken?: string | null,
};

export type ModelUsageReservationFilterInput = {
  id?: ModelIDInput | null,
  accountId?: ModelIDInput | null,
  flowId?: ModelIDInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelUsageReservationFilterInput | null > | null,
  or?: Array< ModelUsageReservationFilterInput | null > | null,
  not?: ModelUsageReservationFilterInput | null,
};

export type ModelUsageReservationConnection = {
  __typename: "ModelUsageReservationConnection",
  items:  Array<UsageReservation | null >,
  nextToken?: string | null,
};

export type ModelSubscriptionAccountFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  userEmail?: ModelSubscriptionStringInput | null,
  stripeCustomerId?: ModelSubscriptionStringInput | null,
  hasActiveSubscription?: ModelSubscriptionBooleanInput | null,
  subscriptionType?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAccountFilterInput | null > | null,
  or?: Array< ModelSubscriptionAccountFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionContentSourceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountId?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  url?: ModelSubscriptionStringInput | null,
  textTitle?: ModelSubscriptionStringInput | null,
  textContent?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionContentSourceFilterInput | null > | null,
  or?: Array< ModelSubscriptionContentSourceFilterInput | null > | null,
};

export type ModelSubscriptionVideoFlowFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountId?: ModelSubscriptionIDInput | null,
  contentSourceId?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  videoUrl?: ModelSubscriptionStringInput | null,
  fileName?: ModelSubscriptionStringInput | null,
  key?: ModelSubscriptionStringInput | null,
  contributions?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionVideoFlowFilterInput | null > | null,
  or?: Array< ModelSubscriptionVideoFlowFilterInput | null > | null,
};

export type ModelSubscriptionContentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountId?: ModelSubscriptionIDInput | null,
  contentSourceId?: ModelSubscriptionIDInput | null,
  format?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  url?: ModelSubscriptionStringInput | null,
  key?: ModelSubscriptionStringInput | null,
  contributions?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionContentFilterInput | null > | null,
  or?: Array< ModelSubscriptionContentFilterInput | null > | null,
};

export type ModelSubscriptionUsageFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountId?: ModelSubscriptionIDInput | null,
  from?: ModelSubscriptionStringInput | null,
  to?: ModelSubscriptionStringInput | null,
  usage?: ModelSubscriptionIntInput | null,
  allowedUsage?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionUsageFilterInput | null > | null,
  or?: Array< ModelSubscriptionUsageFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionUsageReservationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountId?: ModelSubscriptionIDInput | null,
  flowId?: ModelSubscriptionIDInput | null,
  date?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUsageReservationFilterInput | null > | null,
  or?: Array< ModelSubscriptionUsageReservationFilterInput | null > | null,
};

export type CreateAccountMutationVariables = {
  input: CreateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type CreateAccountMutation = {
  createAccount?:  {
    __typename: "Account",
    id: string,
    userId: string,
    userEmail: string,
    stripeCustomerId: string,
    hasActiveSubscription: boolean,
    subscriptionType: SubscriptionType,
    contentSources?:  {
      __typename: "ModelContentSourceConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAccountMutationVariables = {
  input: UpdateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type UpdateAccountMutation = {
  updateAccount?:  {
    __typename: "Account",
    id: string,
    userId: string,
    userEmail: string,
    stripeCustomerId: string,
    hasActiveSubscription: boolean,
    subscriptionType: SubscriptionType,
    contentSources?:  {
      __typename: "ModelContentSourceConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAccountMutationVariables = {
  input: DeleteAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type DeleteAccountMutation = {
  deleteAccount?:  {
    __typename: "Account",
    id: string,
    userId: string,
    userEmail: string,
    stripeCustomerId: string,
    hasActiveSubscription: boolean,
    subscriptionType: SubscriptionType,
    contentSources?:  {
      __typename: "ModelContentSourceConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateContentSourceMutationVariables = {
  input: CreateContentSourceInput,
  condition?: ModelContentSourceConditionInput | null,
};

export type CreateContentSourceMutation = {
  createContentSource?:  {
    __typename: "ContentSource",
    id: string,
    accountId: string,
    type?: ContentSourceType | null,
    url?: string | null,
    textTitle?: string | null,
    textContent?: string | null,
    contents?:  {
      __typename: "ModelContentConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateContentSourceMutationVariables = {
  input: UpdateContentSourceInput,
  condition?: ModelContentSourceConditionInput | null,
};

export type UpdateContentSourceMutation = {
  updateContentSource?:  {
    __typename: "ContentSource",
    id: string,
    accountId: string,
    type?: ContentSourceType | null,
    url?: string | null,
    textTitle?: string | null,
    textContent?: string | null,
    contents?:  {
      __typename: "ModelContentConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteContentSourceMutationVariables = {
  input: DeleteContentSourceInput,
  condition?: ModelContentSourceConditionInput | null,
};

export type DeleteContentSourceMutation = {
  deleteContentSource?:  {
    __typename: "ContentSource",
    id: string,
    accountId: string,
    type?: ContentSourceType | null,
    url?: string | null,
    textTitle?: string | null,
    textContent?: string | null,
    contents?:  {
      __typename: "ModelContentConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateVideoFlowMutationVariables = {
  input: CreateVideoFlowInput,
  condition?: ModelVideoFlowConditionInput | null,
};

export type CreateVideoFlowMutation = {
  createVideoFlow?:  {
    __typename: "VideoFlow",
    id: string,
    accountId: string,
    contentSourceId: string,
    status: JobStatus,
    videoUrl?: string | null,
    fileName?: string | null,
    key?: string | null,
    contributions?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateVideoFlowMutationVariables = {
  input: UpdateVideoFlowInput,
  condition?: ModelVideoFlowConditionInput | null,
};

export type UpdateVideoFlowMutation = {
  updateVideoFlow?:  {
    __typename: "VideoFlow",
    id: string,
    accountId: string,
    contentSourceId: string,
    status: JobStatus,
    videoUrl?: string | null,
    fileName?: string | null,
    key?: string | null,
    contributions?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteVideoFlowMutationVariables = {
  input: DeleteVideoFlowInput,
  condition?: ModelVideoFlowConditionInput | null,
};

export type DeleteVideoFlowMutation = {
  deleteVideoFlow?:  {
    __typename: "VideoFlow",
    id: string,
    accountId: string,
    contentSourceId: string,
    status: JobStatus,
    videoUrl?: string | null,
    fileName?: string | null,
    key?: string | null,
    contributions?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateContentMutationVariables = {
  input: CreateContentInput,
  condition?: ModelContentConditionInput | null,
};

export type CreateContentMutation = {
  createContent?:  {
    __typename: "Content",
    id: string,
    accountId: string,
    contentSourceId: string,
    format?: ContentFormat | null,
    type?: ContentType | null,
    url?: string | null,
    key?: string | null,
    contributions?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateContentMutationVariables = {
  input: UpdateContentInput,
  condition?: ModelContentConditionInput | null,
};

export type UpdateContentMutation = {
  updateContent?:  {
    __typename: "Content",
    id: string,
    accountId: string,
    contentSourceId: string,
    format?: ContentFormat | null,
    type?: ContentType | null,
    url?: string | null,
    key?: string | null,
    contributions?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteContentMutationVariables = {
  input: DeleteContentInput,
  condition?: ModelContentConditionInput | null,
};

export type DeleteContentMutation = {
  deleteContent?:  {
    __typename: "Content",
    id: string,
    accountId: string,
    contentSourceId: string,
    format?: ContentFormat | null,
    type?: ContentType | null,
    url?: string | null,
    key?: string | null,
    contributions?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUsageMutationVariables = {
  input: CreateUsageInput,
  condition?: ModelUsageConditionInput | null,
};

export type CreateUsageMutation = {
  createUsage?:  {
    __typename: "Usage",
    id: string,
    accountId: string,
    from: string,
    to: string,
    usage: number,
    allowedUsage: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUsageMutationVariables = {
  input: UpdateUsageInput,
  condition?: ModelUsageConditionInput | null,
};

export type UpdateUsageMutation = {
  updateUsage?:  {
    __typename: "Usage",
    id: string,
    accountId: string,
    from: string,
    to: string,
    usage: number,
    allowedUsage: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUsageMutationVariables = {
  input: DeleteUsageInput,
  condition?: ModelUsageConditionInput | null,
};

export type DeleteUsageMutation = {
  deleteUsage?:  {
    __typename: "Usage",
    id: string,
    accountId: string,
    from: string,
    to: string,
    usage: number,
    allowedUsage: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUsageReservationMutationVariables = {
  input: CreateUsageReservationInput,
  condition?: ModelUsageReservationConditionInput | null,
};

export type CreateUsageReservationMutation = {
  createUsageReservation?:  {
    __typename: "UsageReservation",
    id: string,
    accountId: string,
    flowId: string,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUsageReservationMutationVariables = {
  input: UpdateUsageReservationInput,
  condition?: ModelUsageReservationConditionInput | null,
};

export type UpdateUsageReservationMutation = {
  updateUsageReservation?:  {
    __typename: "UsageReservation",
    id: string,
    accountId: string,
    flowId: string,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUsageReservationMutationVariables = {
  input: DeleteUsageReservationInput,
  condition?: ModelUsageReservationConditionInput | null,
};

export type DeleteUsageReservationMutation = {
  deleteUsageReservation?:  {
    __typename: "UsageReservation",
    id: string,
    accountId: string,
    flowId: string,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetAccountQueryVariables = {
  id: string,
};

export type GetAccountQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    userId: string,
    userEmail: string,
    stripeCustomerId: string,
    hasActiveSubscription: boolean,
    subscriptionType: SubscriptionType,
    contentSources?:  {
      __typename: "ModelContentSourceConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAccountsQueryVariables = {
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountsQuery = {
  listAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      userId: string,
      userEmail: string,
      stripeCustomerId: string,
      hasActiveSubscription: boolean,
      subscriptionType: SubscriptionType,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AccountByUserQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AccountByUserQuery = {
  accountByUser?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      userId: string,
      userEmail: string,
      stripeCustomerId: string,
      hasActiveSubscription: boolean,
      subscriptionType: SubscriptionType,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AccountsByStripeCustomerIdQueryVariables = {
  stripeCustomerId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AccountsByStripeCustomerIdQuery = {
  accountsByStripeCustomerId?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      userId: string,
      userEmail: string,
      stripeCustomerId: string,
      hasActiveSubscription: boolean,
      subscriptionType: SubscriptionType,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetContentSourceQueryVariables = {
  id: string,
};

export type GetContentSourceQuery = {
  getContentSource?:  {
    __typename: "ContentSource",
    id: string,
    accountId: string,
    type?: ContentSourceType | null,
    url?: string | null,
    textTitle?: string | null,
    textContent?: string | null,
    contents?:  {
      __typename: "ModelContentConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListContentSourcesQueryVariables = {
  filter?: ModelContentSourceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContentSourcesQuery = {
  listContentSources?:  {
    __typename: "ModelContentSourceConnection",
    items:  Array< {
      __typename: "ContentSource",
      id: string,
      accountId: string,
      type?: ContentSourceType | null,
      url?: string | null,
      textTitle?: string | null,
      textContent?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContentSourceByAccountQueryVariables = {
  accountId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContentSourceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContentSourceByAccountQuery = {
  contentSourceByAccount?:  {
    __typename: "ModelContentSourceConnection",
    items:  Array< {
      __typename: "ContentSource",
      id: string,
      accountId: string,
      type?: ContentSourceType | null,
      url?: string | null,
      textTitle?: string | null,
      textContent?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContentSourceByUrlQueryVariables = {
  url: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContentSourceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContentSourceByUrlQuery = {
  contentSourceByUrl?:  {
    __typename: "ModelContentSourceConnection",
    items:  Array< {
      __typename: "ContentSource",
      id: string,
      accountId: string,
      type?: ContentSourceType | null,
      url?: string | null,
      textTitle?: string | null,
      textContent?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVideoFlowQueryVariables = {
  id: string,
};

export type GetVideoFlowQuery = {
  getVideoFlow?:  {
    __typename: "VideoFlow",
    id: string,
    accountId: string,
    contentSourceId: string,
    status: JobStatus,
    videoUrl?: string | null,
    fileName?: string | null,
    key?: string | null,
    contributions?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListVideoFlowsQueryVariables = {
  filter?: ModelVideoFlowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVideoFlowsQuery = {
  listVideoFlows?:  {
    __typename: "ModelVideoFlowConnection",
    items:  Array< {
      __typename: "VideoFlow",
      id: string,
      accountId: string,
      contentSourceId: string,
      status: JobStatus,
      videoUrl?: string | null,
      fileName?: string | null,
      key?: string | null,
      contributions?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VideoFlowsByAccountQueryVariables = {
  accountId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVideoFlowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VideoFlowsByAccountQuery = {
  videoFlowsByAccount?:  {
    __typename: "ModelVideoFlowConnection",
    items:  Array< {
      __typename: "VideoFlow",
      id: string,
      accountId: string,
      contentSourceId: string,
      status: JobStatus,
      videoUrl?: string | null,
      fileName?: string | null,
      key?: string | null,
      contributions?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VideoFlowsByContentSourceQueryVariables = {
  contentSourceId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVideoFlowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VideoFlowsByContentSourceQuery = {
  videoFlowsByContentSource?:  {
    __typename: "ModelVideoFlowConnection",
    items:  Array< {
      __typename: "VideoFlow",
      id: string,
      accountId: string,
      contentSourceId: string,
      status: JobStatus,
      videoUrl?: string | null,
      fileName?: string | null,
      key?: string | null,
      contributions?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetContentQueryVariables = {
  id: string,
};

export type GetContentQuery = {
  getContent?:  {
    __typename: "Content",
    id: string,
    accountId: string,
    contentSourceId: string,
    format?: ContentFormat | null,
    type?: ContentType | null,
    url?: string | null,
    key?: string | null,
    contributions?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListContentsQueryVariables = {
  filter?: ModelContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContentsQuery = {
  listContents?:  {
    __typename: "ModelContentConnection",
    items:  Array< {
      __typename: "Content",
      id: string,
      accountId: string,
      contentSourceId: string,
      format?: ContentFormat | null,
      type?: ContentType | null,
      url?: string | null,
      key?: string | null,
      contributions?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContentByAccountQueryVariables = {
  accountId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContentByAccountQuery = {
  contentByAccount?:  {
    __typename: "ModelContentConnection",
    items:  Array< {
      __typename: "Content",
      id: string,
      accountId: string,
      contentSourceId: string,
      format?: ContentFormat | null,
      type?: ContentType | null,
      url?: string | null,
      key?: string | null,
      contributions?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ContentByContentSourceQueryVariables = {
  contentSourceId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContentByContentSourceQuery = {
  contentByContentSource?:  {
    __typename: "ModelContentConnection",
    items:  Array< {
      __typename: "Content",
      id: string,
      accountId: string,
      contentSourceId: string,
      format?: ContentFormat | null,
      type?: ContentType | null,
      url?: string | null,
      key?: string | null,
      contributions?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUsageQueryVariables = {
  id: string,
};

export type GetUsageQuery = {
  getUsage?:  {
    __typename: "Usage",
    id: string,
    accountId: string,
    from: string,
    to: string,
    usage: number,
    allowedUsage: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsagesQueryVariables = {
  filter?: ModelUsageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsagesQuery = {
  listUsages?:  {
    __typename: "ModelUsageConnection",
    items:  Array< {
      __typename: "Usage",
      id: string,
      accountId: string,
      from: string,
      to: string,
      usage: number,
      allowedUsage: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsageByAccountQueryVariables = {
  accountId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUsageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsageByAccountQuery = {
  usageByAccount?:  {
    __typename: "ModelUsageConnection",
    items:  Array< {
      __typename: "Usage",
      id: string,
      accountId: string,
      from: string,
      to: string,
      usage: number,
      allowedUsage: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUsageReservationQueryVariables = {
  id: string,
};

export type GetUsageReservationQuery = {
  getUsageReservation?:  {
    __typename: "UsageReservation",
    id: string,
    accountId: string,
    flowId: string,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsageReservationsQueryVariables = {
  filter?: ModelUsageReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsageReservationsQuery = {
  listUsageReservations?:  {
    __typename: "ModelUsageReservationConnection",
    items:  Array< {
      __typename: "UsageReservation",
      id: string,
      accountId: string,
      flowId: string,
      date: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsageReservationByAccountQueryVariables = {
  accountId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUsageReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsageReservationByAccountQuery = {
  usageReservationByAccount?:  {
    __typename: "ModelUsageReservationConnection",
    items:  Array< {
      __typename: "UsageReservation",
      id: string,
      accountId: string,
      flowId: string,
      date: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsageReservationByFlowQueryVariables = {
  flowId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUsageReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsageReservationByFlowQuery = {
  usageReservationByFlow?:  {
    __typename: "ModelUsageReservationConnection",
    items:  Array< {
      __typename: "UsageReservation",
      id: string,
      accountId: string,
      flowId: string,
      date: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnCreateAccountSubscription = {
  onCreateAccount?:  {
    __typename: "Account",
    id: string,
    userId: string,
    userEmail: string,
    stripeCustomerId: string,
    hasActiveSubscription: boolean,
    subscriptionType: SubscriptionType,
    contentSources?:  {
      __typename: "ModelContentSourceConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnUpdateAccountSubscription = {
  onUpdateAccount?:  {
    __typename: "Account",
    id: string,
    userId: string,
    userEmail: string,
    stripeCustomerId: string,
    hasActiveSubscription: boolean,
    subscriptionType: SubscriptionType,
    contentSources?:  {
      __typename: "ModelContentSourceConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnDeleteAccountSubscription = {
  onDeleteAccount?:  {
    __typename: "Account",
    id: string,
    userId: string,
    userEmail: string,
    stripeCustomerId: string,
    hasActiveSubscription: boolean,
    subscriptionType: SubscriptionType,
    contentSources?:  {
      __typename: "ModelContentSourceConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateContentSourceSubscriptionVariables = {
  filter?: ModelSubscriptionContentSourceFilterInput | null,
};

export type OnCreateContentSourceSubscription = {
  onCreateContentSource?:  {
    __typename: "ContentSource",
    id: string,
    accountId: string,
    type?: ContentSourceType | null,
    url?: string | null,
    textTitle?: string | null,
    textContent?: string | null,
    contents?:  {
      __typename: "ModelContentConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateContentSourceSubscriptionVariables = {
  filter?: ModelSubscriptionContentSourceFilterInput | null,
};

export type OnUpdateContentSourceSubscription = {
  onUpdateContentSource?:  {
    __typename: "ContentSource",
    id: string,
    accountId: string,
    type?: ContentSourceType | null,
    url?: string | null,
    textTitle?: string | null,
    textContent?: string | null,
    contents?:  {
      __typename: "ModelContentConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteContentSourceSubscriptionVariables = {
  filter?: ModelSubscriptionContentSourceFilterInput | null,
};

export type OnDeleteContentSourceSubscription = {
  onDeleteContentSource?:  {
    __typename: "ContentSource",
    id: string,
    accountId: string,
    type?: ContentSourceType | null,
    url?: string | null,
    textTitle?: string | null,
    textContent?: string | null,
    contents?:  {
      __typename: "ModelContentConnection",
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateVideoFlowSubscriptionVariables = {
  filter?: ModelSubscriptionVideoFlowFilterInput | null,
};

export type OnCreateVideoFlowSubscription = {
  onCreateVideoFlow?:  {
    __typename: "VideoFlow",
    id: string,
    accountId: string,
    contentSourceId: string,
    status: JobStatus,
    videoUrl?: string | null,
    fileName?: string | null,
    key?: string | null,
    contributions?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateVideoFlowSubscriptionVariables = {
  filter?: ModelSubscriptionVideoFlowFilterInput | null,
};

export type OnUpdateVideoFlowSubscription = {
  onUpdateVideoFlow?:  {
    __typename: "VideoFlow",
    id: string,
    accountId: string,
    contentSourceId: string,
    status: JobStatus,
    videoUrl?: string | null,
    fileName?: string | null,
    key?: string | null,
    contributions?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteVideoFlowSubscriptionVariables = {
  filter?: ModelSubscriptionVideoFlowFilterInput | null,
};

export type OnDeleteVideoFlowSubscription = {
  onDeleteVideoFlow?:  {
    __typename: "VideoFlow",
    id: string,
    accountId: string,
    contentSourceId: string,
    status: JobStatus,
    videoUrl?: string | null,
    fileName?: string | null,
    key?: string | null,
    contributions?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateContentSubscriptionVariables = {
  filter?: ModelSubscriptionContentFilterInput | null,
};

export type OnCreateContentSubscription = {
  onCreateContent?:  {
    __typename: "Content",
    id: string,
    accountId: string,
    contentSourceId: string,
    format?: ContentFormat | null,
    type?: ContentType | null,
    url?: string | null,
    key?: string | null,
    contributions?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateContentSubscriptionVariables = {
  filter?: ModelSubscriptionContentFilterInput | null,
};

export type OnUpdateContentSubscription = {
  onUpdateContent?:  {
    __typename: "Content",
    id: string,
    accountId: string,
    contentSourceId: string,
    format?: ContentFormat | null,
    type?: ContentType | null,
    url?: string | null,
    key?: string | null,
    contributions?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteContentSubscriptionVariables = {
  filter?: ModelSubscriptionContentFilterInput | null,
};

export type OnDeleteContentSubscription = {
  onDeleteContent?:  {
    __typename: "Content",
    id: string,
    accountId: string,
    contentSourceId: string,
    format?: ContentFormat | null,
    type?: ContentType | null,
    url?: string | null,
    key?: string | null,
    contributions?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUsageSubscriptionVariables = {
  filter?: ModelSubscriptionUsageFilterInput | null,
};

export type OnCreateUsageSubscription = {
  onCreateUsage?:  {
    __typename: "Usage",
    id: string,
    accountId: string,
    from: string,
    to: string,
    usage: number,
    allowedUsage: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUsageSubscriptionVariables = {
  filter?: ModelSubscriptionUsageFilterInput | null,
};

export type OnUpdateUsageSubscription = {
  onUpdateUsage?:  {
    __typename: "Usage",
    id: string,
    accountId: string,
    from: string,
    to: string,
    usage: number,
    allowedUsage: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUsageSubscriptionVariables = {
  filter?: ModelSubscriptionUsageFilterInput | null,
};

export type OnDeleteUsageSubscription = {
  onDeleteUsage?:  {
    __typename: "Usage",
    id: string,
    accountId: string,
    from: string,
    to: string,
    usage: number,
    allowedUsage: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUsageReservationSubscriptionVariables = {
  filter?: ModelSubscriptionUsageReservationFilterInput | null,
};

export type OnCreateUsageReservationSubscription = {
  onCreateUsageReservation?:  {
    __typename: "UsageReservation",
    id: string,
    accountId: string,
    flowId: string,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUsageReservationSubscriptionVariables = {
  filter?: ModelSubscriptionUsageReservationFilterInput | null,
};

export type OnUpdateUsageReservationSubscription = {
  onUpdateUsageReservation?:  {
    __typename: "UsageReservation",
    id: string,
    accountId: string,
    flowId: string,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUsageReservationSubscriptionVariables = {
  filter?: ModelSubscriptionUsageReservationFilterInput | null,
};

export type OnDeleteUsageReservationSubscription = {
  onDeleteUsageReservation?:  {
    __typename: "UsageReservation",
    id: string,
    accountId: string,
    flowId: string,
    date: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};
